<script>
export default {
    name: 'ContentDiscoveryMixin',
    computed: {
        logicJumpText() {
            const text = [];
            const logicJump = this.data?.logicJump;
            if (logicJump?.channel?.text) text.push(logicJump.channel.text);
            if (logicJump?.convo?.text) text.push(logicJump.convo.text);
            return text.join(' • ');
        },
    },
    methods: {
        onInput(key, value, validate = false) {
            this.$emit('input', { key, value, validate });
        },
    },
};
</script>
