<template>
    <div class="content-discovery-menu-custom">
        <div>
            <Input
                id="title"
                type="text"
                label="Title"
                placeholder="Enter title"
                name="title"
                :max="80"
                :has-margin-bottom="false"
                class="m-b-1"
                show-remaining-char
                :value="data.title"
                :errors="errors.title"
                @input="onInput('title', $event)"
            />

            <RadioButtonGroup
                :has-margin-bottom="false"
                class="m-b-2"
                label="Link Type"
                name="type"
                is-horizontal
                is-form-field-styled
                :options="[
                    { value: 'link', name: 'External Link' },
                    { value: 'logicJump', name: 'Logic Jump' },
                ]"
                :value="data.type"
                @input="onInput('type', $event)"
            />

            <Input
                v-if="data.type === 'link'"
                id="linkUrl"
                type="text"
                placeholder="Enter external link"
                label="External Link"
                name="external-link"
                class="m-b-2"
                :has-margin-bottom="false"
                :value="data.linkUrl"
                :errors="errors.linkUrl"
                @input="onInput('linkUrl', $event, true)"
            />

            <RadioButtonGroup
                v-if="data.type === 'link'"
                label="Link Behaviour"
                name="link-target"
                is-horizontal
                is-form-field-styled
                :has-margin-bottom="false"
                :options="[
                    { value: 'internal', name: 'Open in Same Window' },
                    { value: 'blank', name: 'Open in New Window' },
                ]"
                :value="data.linkTarget"
                @input="onInput('linkTarget', $event)"
            />

            <FormInfoBox
                v-if="data.type === 'logicJump'"
                button-text="Edit Search"
                :message="logicJumpText"
                :errors="errors.logicJump"
                @click="showContentSelector = true"
            >
                <Input
                    id="logic-jump"
                    type="text"
                    placeholder="Search for a Channel or Convo"
                    label="Logic Jump"
                    name="logic-jump"
                    default-icon="search"
                    :default-icon-size="16"
                    :has-margin-bottom="false"
                    @focus="showContentSelector = true"
                />
            </FormInfoBox>

            <ContentSelector
                v-if="showContentSelector"
                v-model="logicJump"
                validation-content-type="convo"
                :show-content-id="true"
                @close="showContentSelector = false"
            />
        </div>
        <div>
            <ImageUploaderButton
                show-preview
                input-style="green"
                button-label="Add image"
                thumbnail-location="top"
                :thumbnail-size="100"
                :aspect-ratio="109 / 83"
                :value="data.image"
                :errors="errors.image"
                @input="onInput('image', $event)"
            />
        </div>
    </div>
</template>

<script>
import { ImageUploaderButton, RadioButtonGroup, FormInfoBox, Input } from '@/components/forms';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import ContentDiscoveryMixin from '@/mixins/contentDiscoveryMixin';
import { LogicJump } from '@/store/models';

export default {
    name: 'ContentDiscoveryMenuCustom',
    components: {
        Input,
        FormInfoBox,
        ContentSelector,
        ImageUploaderButton,
        RadioButtonGroup,
    },
    mixins: [ContentDiscoveryMixin],
    props: {
        data: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showContentSelector: false,
        };
    },
    computed: {
        logicJump: {
            get() {
                return this.data?.logicJump?.items || {};
            },
            set(content) {
                this.onInput('logicJump', new LogicJump(content));
            },
        },
        logicJumpText() {
            const text = [];
            const logicJump = this.data?.logicJump;
            if (logicJump?.channel?.text) text.push(logicJump.channel.text);
            if (logicJump?.convo?.text) text.push(logicJump.convo.text);
            return text.join(' • ');
        },
    },
};
</script>
<style lang="scss" scoped>
.content-discovery-menu-custom {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-gap: 20px;
}
</style>
